.wordSearch {
  text-align: center;
  font-size: 48px;
  font-family: Dustismo, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wordSearch__cell {
  padding: 8px;
}

.status {
  padding: 6px;
  display: inline-block;
  margin: 6px 0;
  vertical-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

.status--warning {
  background-color: hsl(44.2, 91.6%, 62.5%);
  border-top: 3px hsl(44.2, 41.6%, 40%) solid;
  border-bottom: 3px hsl(44.2, 41.6%, 40%) solid;
  color: hsl(44.2, 41.6%, 40%);
}

.wordMatch0 {
  background-color: #ef4e4e;
}

.wordMatch1 {
  background-color: #f9703e;
}

.wordMatch2 {
  background-color: #f7c948;
}

.wordMatch3 {
  background-color: #8ded2d;
}

.wordMatch4 {
  background-color: #31b237;
}

.wordMatch5 {
  background-color: #2dcca7;
}

.wordMatch6 {
  background-color: #3ae7e1;
}

.wordMatch7 {
  background-color: #40c3f7;
}

.wordMatch8 {
  background-color: #2186eb;
}

.wordMatch9 {
  background-color: #3a66db;
}

.wordMatch10 {
  background-color: #9446ed;
}

.wordMatch11 {
  background-color: #ed47ed;
}

.wordMatch12 {
  background-color: #e8368f;
}
